<template>
     <div class="optimize_main">
          <div class="con2">
               <div class="layer8 flex-col">
                    <div class="outer2 flex-row">
                         <div class="mod1 flex_row_center_center">
                              <span class="word10">1</span>
                              <span class="word7">{{L['设计师描述需求，上传申请资料']}}</span>
                         </div>
                         <div class="mod1 flex_row_center_center">
                              <span class="word10">2</span>
                              <span class="word7">{{L['供应商客服处理']}}</span>
                         </div>
                         <div class="mod1 flex_row_center_center">
                              <span class="word10">3</span>
                              <span class="word7">{{L['设计师查看样品申请单']}}</span>
                         </div>
                    </div>
               </div>
               <div class="layer9 flex_column_start_center">
                    <div class="his flex_row_end_center">
                         <router-link :to="'/prototype/sample/history'">
                              <span class="option2">{{L['历史记录']}}>></span>
                         </router-link>
                    </div>
                    <table style="border-spacing:20px;">
                         <tr>
                              <td class="td_title"><span><i class="ico">*</i>{{L['收货地址：']}}</span></td>
                              <td>
                                   <div class="address already_chosen" v-if="orderAddress.data.addressId">
                                        {{ orderAddress.data.memberName }} &nbsp;
                                        {{ orderAddress.data.telMobile }}&nbsp;
                                        {{ orderAddress.data.addressAll }}&nbsp;
                                        {{ orderAddress.data.detailAddress }}
                                   </div>
                                   <div class="address flex_row_start_center" v-else>
                                        <span>{{L['暂无收货地址']}} &nbsp;</span>
                                   </div>
                              </td>
                         </tr>
                         <tr>
                              <td class="td_title"></td>
                              <td>
                                   <div class="address_line flex_row_between_center">
                                        <div class="address_btn flex_row_center_center" @click="addAddress">
                                             <span>+ {{L['新增收货地址']}}</span>
                                        </div>

                                        <div class="address_all" @click="changeAddress">
                                             <span>{{L['全部地址']}}</span>
                                             <span class="iconfont iconziyuan111"></span>
                                        </div>

                                   </div>

                              </td>
                         </tr>
                         <tr>
                              <td class="td_title"><span><i class="ico">*</i>{{L['供应商：']}}</span></td>
                              <td>
                                   <div v-if="vnList.length">
                                        <el-select v-model="curVn" :placeholder="L['请选择']" @change="stateChange">
                                             <el-option v-for="(item, index) in vnList" :key="index"
                                                  :label="item.labelVal" :value="item.storeId">
                                             </el-option>
                                        </el-select>
                                   </div>

                              </td>
                         </tr>
                         <tr>
                              <td class="td_title" valign="top"><span>{{L['需求描述：']}}</span></td>
                              <td>
                                   <el-input type="textarea" rows="4" maxlength="200" show-word-limit
                                        :placeholder="L['请输入您需要申请产品的名称、规格型号、数量等信息']" v-model="applyDesc"></el-input>
                              </td>
                         </tr>
                         <tr>
                              <td class="td_title" valign="top"><span>{{L['申请资料：']}}</span></td>
                              <td>
                                   <div class="outer2 flex-col" id="dropZone" :v-loading="uploading"
                                        v-show="applyFile.length < 5">
                                        <div class="mod2 flex_column_center_center">
                                             <img class="img1" referrerpolicy="no-referrer"
                                                  src="../../../assets/member/sketch_file.png" />
                                             <div class="layer1">
                                                  <span class="word2">{{L['将文件拖入框内，或']}}</span>
                                                  <label for="upload">
                                                       <span class="word3">{{L['点击上传']}}</span>
                                                  </label>
                                                  <input type="file" name="" id="upload" size="1"
                                                       accept="image/png,image/jpeg,image/jpg,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                                       @change="getFile" multiple ref="inputTab">
                                             </div>
                                             <div class="layer22">
                                                  <span>
                                                       {{L['支持上传.xls文件和JPEG、PNG格式的图片']}}
                                                  </span>
                                             </div>
                                        </div>
                                   </div>

                                   <div class="layer_success" v-show="false">
                                        <div class="success_excel flex_row_between_center"
                                             v-if="applyFile.type == 'xlsx' || applyFile.type == 'xls'">
                                             <div class="left flex_row_start_center">
                                                  <img src="../../../assets/prototype/excel.png" alt="">
                                                  <span>{{L['选型清单']}}</span>
                                             </div>
                                             <div class="right">
                                                  <div class="right_con" @click="clearFile">
                                                       <span class="el-icon-delete"></span>
                                                  </div>
                                             </div>
                                        </div>
                                        <div class="success_img" v-else>
                                             <div class="img_con"
                                                  :style="{ backgroundImage: 'url(' + applyFile.url + ')' }">
                                             </div>
                                             <div class="del_con" @click="clearFile">
                                                  <span class="el-icon-delete"></span>
                                             </div>
                                        </div>
                                   </div>
                              </td>
                         </tr>
                         <tr>
                              <td></td>
                              <td>
                                   <div class="upload_list flex_row_start_center">
                                        <div class="upload_item" v-for="(item, index) in applyFile" :key="index">
                                             <span class="el-icon-error" @click="clearFileList(index)"></span>
                                             <div v-if="item.type == 'xlsx' || item.type == 'xls'"
                                                  class="excel_go flex_column_center_center">
                                                  <img src="../../../assets/prototype/excel.png" alt="">
                                                  <p>{{L['选型清单']}}</p>
                                             </div>
                                             <div class="img_con" :style="{ backgroundImage: 'url(' + item.url + ')' }"
                                                  v-else>
                                             </div>
                                        </div>
                                   </div>
                              </td>
                         </tr>
                         <tr>
                              <td></td>
                              <el-button type="primary" :loading="submitLoading" class="confirmBtn"
                                   @click="optimiSubmit">{{L['确认']}}
                              </el-button>
                         </tr>
                    </table>
               </div>
          </div>

          <!-- 地址选择弹窗 start -->
          <el-dialog :title="L['更换地址']" v-model="show_select_address" customClass="select_address_width"
               :before-close="handleClose" lock-sroll="false">
               <div class="address_con">
                    <div v-for="(item, index) in address_list" :key="index" @click="selectAddress(index)"
                         :class="{ address_item: true, flex_column_start_start: true, select: current_address_index == index }">
                         <span>{{ L['收货人'] }}：{{ item.memberName }}</span>
                         <span>{{ L['联系方式'] }}：{{ item.telMobile }}</span>
                         <div class="address_text" :title="item.addressAll + item.detailAddress">
                              <span class="addr_name">{{ L['收货地址'] }}：</span>
                              <span class="overflow_ellipsis_clamp2">{{ item.addressAll }} {{
                                   item.detailAddress
                              }}</span>
                         </div>
                         <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
                    </div>
               </div>
               <div class="btn_con flex_row_center_center">
                    <div class="remove red" @click="confirmChangeAddress()">{{ L['提交修改'] }}</div>
               </div>
          </el-dialog>
          <!-- 地址选择弹窗 end -->

          <SldAddressOperation v-if="addressVisibleFlag" @close="close" @refreshAddress="refreshAddress"
               :visibleFlag="true" type="add" />

     </div>
</template>

<script>
import { ref, getCurrentInstance, onMounted, reactive, onBeforeMount } from 'vue';
import { ElMessage } from 'element-plus';
import { useRouter } from 'vue-router';
import SldAddressOperation from "@/components/AddressOperation";


export default {
     components: {
          SldAddressOperation
     },

     setup() {
          const { proxy } = getCurrentInstance()
          const router = useRouter()
          const vnList = ref([])
          const curVn = ref(null)
          const applyDesc = ref('')
          const submitLoading = ref(false)
          const show_select_address = ref(false)
          const orderAddress = reactive({ data: {} })
          const address_list = ref([])
          const L = proxy.$getCurLanguage()
          const current_address_index = ref(0)
          const applyFile = ref([])
          const addressVisibleFlag = ref(false)



          const getVendorList = () => {
               let paramVendor = {
                    current: 1,
                    type: 3
               }
               proxy.$get('v3/seller/front/store/storeList', paramVendor).then(res => {
                    if (res.state == 200) {
                         if (res.data.length > 0) {
                              res.data.map(item => {
                                   item.labelVal = `${item.storeName}`

                                   if (item.vendorMobile) {
                                        item.labelVal += ` (${item.vendorTrueName || ''} ${item.vendorMobile})`
                                   } else if (item.servicePhone) {
                                        item.labelVal += ` (${item.servicePhone})`
                                   }

                              })
                              vnList.value = res.data;

                         } else {
                              vnList.value = [''];

                         }
                    }
               })
          }
          const uploading = ref(false)
          const loadState = ref('do')

          const fileDrag = () => {
               var dz = document.getElementById('dropZone');
               dz.ondragover = function (e) {
                    //阻止浏览器默认打开文件的操作  
                    e.preventDefault();
                    e.stopPropagation();
                    //拖入文件后边框颜色变红  
                    e.dataTransfer.dropEffect = 'copy';
               }
               dz.ondragleave = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    //恢复边框颜色  
                    this.style.borderColor = 'gray';
               }
               dz.ondrop = function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    //恢复边框颜色  
                    this.style.borderColor = '#d9d9d9';
                    //阻止浏览器默认打开文件的操作  
                    e.preventDefault();
                    let filesTarget = e.dataTransfer.files;
                    uploadFile(filesTarget)
               }
          }

          const getFile = (e) => {
               if (applyFile.value.length + e.target.files.length > 5) {
                    let needFiles = Array.from(e.target.files).slice(0, 5 - applyFile.value.length)
                    uploadFile(needFiles)
               } else {
                    uploadFile(e.target.files)
               }
          }

          const clearFile = () => {
               applyFile.url = ''
               applyFile.path = ''
               applyFile.type = '';
               loadState.value = 'do'
          }

          // 上传文件
          const uploadFile = (info) => {

               let typelist = ['image/png', 'image/jpeg', 'image/jpg', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
               for (let i = 0; i < info.length; i++) {
                    let file = info[i]
                    let index = typelist.findIndex(i => file.type == i)
                    if (index < 0) {
                         ElMessage(L['上传的文件格式不正确'])
                         return
                    }
                    if (file) {
                         uploading.value = true
                         proxy.$post('v3/oss/front/upload', {
                              source: 'demand',
                              file
                         }, 'form').then(res => {
                              if (res.state == 200) {
                                   loadState.value = 'done'
                                   uploading.value = false
                                   proxy.$refs.inputTab.value = ''
                                   applyFile.value.push({
                                        url: res.data.url,
                                        path: res.data.path,
                                        type: res.data.path.substring(res.data.path.lastIndexOf('.') + 1)
                                   })

                              } else {
                                   ElMessage(res.msg)
                                   uploading.value = false
                              }

                         })
                    }
               }



          }

          //清除单独文件
          const clearFileList = (index) => {
               applyFile.value.splice(index, 1)
          }

          const refreshAddress = (addressInfo, addressId) => {
               orderAddress.data = addressInfo;
               orderAddress.data.addressId = addressId;
               localStorage.setItem('addressId', orderAddress.data.addressId)
          };

          //提交优化清单
          const optimiSubmit = () => {

               if (!orderAddress.data.addressId) {
                    ElMessage(L['请选择地址'])
                    return
               }

               if (!curVn.value) {
                    ElMessage(L['请选择供应商'])
                    return
               }



               if (submitLoading.value) {
                    return
               }
               submitLoading.value = true

               let param = {
                    storeId: curVn.value,
                    addressId: orderAddress.data.addressId
               }

               if (applyFile.value.length) {
                    param.applyFile = applyFile.value.map(i => i.path).join(',')
               }

               if (applyDesc.value.trim()) {
                    param.description = applyDesc.value
               }

               proxy.$post('v3/business/front/sample/apply/submit', param).then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)

                         setTimeout(() => {
                              submitLoading.value = false
                              router.push('/prototype/sample/history')
                         }, 2000)
                    } else {
                         submitLoading.value = false
                         ElMessage(res.msg)
                    }
               })

          }

          const stateChange = () => {
          }


          //弹出地址选择框
          const changeAddress = () => {
               getAddressList();
               show_select_address.value = true;
          };

          //切换地址
          const selectAddress = index => {
               current_address_index.value = index;
          };

          //获取地址列表
          const getAddressList = () => {
               proxy
                    .$get("v3/member/front/memberAddress/list")
                    .then(res => {
                         if (res.state == 200) {
                              address_list.value = res.data.list;
                              if (res.data.list.length > 0 && !orderAddress.data.addressId) {
                                   var default_address = res.data.list.filter(function (item) {
                                        return item.isDefault == 1;
                                   });
                                   if (default_address.length > 0) {
                                        orderAddress.data = default_address[0];
                                   } else {
                                        orderAddress.data = res.data.list[0];
                                   }
                                   if (localStorage.getItem('addressId')) {
                                        let addressID = localStorage.getItem('addressId')
                                        if (res.data.list.filter(i => i.addressId == addressID)[0]) {
                                             orderAddress.data = res.data.list.filter(i => i.addressId == addressID)[0]

                                        }
                                   }
                              }
                         } else {
                              ElMessage(res.msg);
                         }
                    })
          };

          //确认修改地址
          const confirmChangeAddress = () => {
               orderAddress.data = address_list.value[current_address_index.value];
               show_select_address.value = false;
               localStorage.setItem('addressId', orderAddress.value.addressId)
          };

          //弹出新建地址窗口
          const addAddress = () => {
               addressVisibleFlag.value = true;
          };

          //关闭弹窗
          const close = () => {
               addressVisibleFlag.value = false;
          };


          onMounted(() => {
               getAddressList()
               fileDrag()
          })

          onBeforeMount(() => {
               getVendorList()
          })

          return {
               vnList,
               curVn,
               stateChange,
               getFile,
               applyFile,
               uploading,
               loadState,
               clearFile,
               optimiSubmit,
               applyDesc,
               clearFileList,
               orderAddress,
               changeAddress,
               show_select_address,
               address_list,
               L,
               current_address_index,
               selectAddress,
               confirmChangeAddress,
               addAddress,
               addressVisibleFlag,
               close,
               refreshAddress,
               submitLoading
          }
     }
}
</script>
<style lang="scss">
.layer9 {

     .el-input,
     .el-input__inner {
          width: 528px !important;
          border-radius: 0;
     }
}
</style>
<style lang="scss">
.address {
     width: 528px;
     height: 39px;
     line-height: 39px;
     background: #FFFFFF;
     border: 1px solid #D9D9D9;
     opacity: 1;
     padding: 0 15px;
     font-size: 14px;
     font-family: PingFangSC-Regular, PingFang SC;
     font-weight: 400;
     color: $colorH;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;

     &.already_chosen {
          border: 1px solid $colorMain;
          color: $colorTitle2;
     }
}

.address_line {
     .address_btn {
          width: 150px;
          height: 30px;
          border: 1px dashed $colorMain;
          opacity: 1;
          font-size: 14px;
          font-family: PingFangSC-Regular;
          line-height: 22px;
          color: $colorMain;
          cursor: pointer;
     }

     .address_all {
          font-size: 14px;
          font-family: PingFangSC-Regular;
          line-height: 22px;
          color: $colorTitle2;
          cursor: pointer;

          .iconziyuan111 {
               font-size: 12px;
          }
     }
}

.select_address_width {
     min-width: 500px !important;
     max-width: 530px !important;
}

.btn_con {
     font-size: 14px;
     margin-top: 20px;
     cursor: pointer;

     .return {
          cursor: pointer;
          width: 60px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          color: #333333;
          border-radius: 3px;
          border: 1px solid #dddddd;
     }

     .red {
          background-color: $colorMain;
          color: white;
     }

     .remove {
          width: 120px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          border-radius: 3px;
          background-color: $colorMain;
          margin-left: 10px;
          color: white;
          cursor: pointer;
     }
}

.address_con {
     height: 340px;
     overflow-y: auto;
     scrollbar-width: none !important;
     -ms-overflow-style: none !important;

     .address_item {
          &:not(:first-child) {
               margin-top: 20px;
          }

          width: 458px;
          box-sizing: border-box;
          border: 1px solid #dfdfdf;
          position: relative;
          padding: 20px;

          span,
          div:not(:first-child) {
               margin-top: 12px;
          }

          .address_text {
               display: flex;
               width: 400px;

               .addr_name {
                    white-space: nowrap;
               }
          }

          .selected {
               width: 14px;
               height: 14px;
               position: absolute;
               right: 0;
               bottom: 0;
          }
     }

     .select {
          border: 1px solid $colorMain;
     }
}



.optimize_main {
     background-color: rgba(244, 244, 244, 1);
     padding-top: 30px;
     padding-bottom: 50px;

     .con2 {
          width: 1200px;
          margin: 0 auto;


          .layer8 {
               height: 86px;
               background-color: rgba(255, 255, 255, 1);
               margin-top: 20px;
               width: 1200px;
               justify-content: center;
               align-items: center;

               .mod1 {
                    margin-left: 70px;

                    .word10 {
                         display: block;
                         width: 32px;
                         height: 32px;
                         background-color: rgba(40, 95, 222, 1);
                         border-radius: 50%;
                         font-size: 16px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #FFFFFF;
                         text-align: center;
                         line-height: 32px;
                    }

                    .word7 {
                         margin-left: 15px;
                    }
               }

          }

          .layer9 {
               margin-top: 20px;
               background: #fff;
               padding: 30px;
               min-height: 700px;


               .his {
                    width: 600px;

               }


               .option2 {
                    color: $colorMain;
                    border-bottom: 1px solid $colorMain;
                    font-size: 14px;
                    cursor: pointer;
               }

               .td_title {
                    text-align: right;
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: #666666;
               }

               .ico {
                    color: $colorPrice;
                    font-style: normal;
               }

               textarea {
                    resize: none;
               }

               .outer2 {
                    z-index: 9;
                    height: 266px;
                    border: 1px solid rgba(217, 217, 217, 1);
                    background-color: rgba(255, 255, 255, 1);
                    align-self: center;
                    width: 528px;
                    justify-content: center;
                    align-items: center;

                    .mod2 {
                         z-index: auto;

                         height: 102px;

                         .img1 {
                              z-index: 10;
                              width: 50px;
                              height: 50px;
                              align-self: center;
                         }

                         .layer1 {
                              z-index: 16;
                              width: 182px;
                              height: 22px;
                              overflow-wrap: break-word;
                              text-align: left;
                              white-space: nowrap;
                              margin-top: 30px;
                              font-size: 0;

                              .word2 {
                                   font-size: 14px;
                                   font-family: PingFangSC-Regular;
                                   color: rgba(153, 153, 153, 1);
                                   line-height: 22px;
                                   overflow: hidden;
                                   text-overflow: ellipsis;
                              }

                              .word3 {
                                   font-size: 14px;
                                   font-family: PingFangSC-Regular;
                                   color: rgba(40, 95, 222, 1);
                                   line-height: 22px;
                                   overflow: hidden;
                                   text-overflow: ellipsis;
                                   cursor: pointer;
                              }
                         }

                         #upload {
                              display: none;
                         }

                         .layer22 {
                              font-size: 14px;
                              font-family: PingFangSC-Regular, PingFang SC;
                              font-weight: 400;
                              color: $colorH;
                              margin-top: 10px;
                         }
                    }
               }



               .confirmBtn {
                    width: 100px;
                    height: 38px;
                    background: $colorMain;
                    text-align: center;
                    /* line-height: 38px; */
                    color: #fff;
                    margin-top: 20px;
                    cursor: pointer;
               }
          }

          .layer_success {
               .success_excel {
                    height: 62px;
                    border-radius: 2px;
                    border: 1px solid rgba(217, 217, 217, 1);
                    background-color: rgba(255, 255, 255, 1);
                    width: 260px;
                    padding-left: 10px;

                    .left {
                         img {
                              width: 42px;
                              height: 42px;
                         }

                         span {
                              margin-left: 15px;
                              font-size: 14px;
                              font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                              font-weight: 400;
                              color: #333333;
                         }
                    }

                    .right {
                         display: flex;
                         align-self: flex-start;
                         padding-top: 5px;
                         padding-right: 5px;

                         .right_con {
                              cursor: pointer;
                              width: 18px;
                              height: 18px;
                              background: #999;
                              border-radius: 50%;
                              text-align: center;
                              line-height: 18px;
                              align-self: flex-start;

                              .el-icon-delete {
                                   color: #fff;
                              }
                         }

                    }



               }

               .success_img {
                    position: relative;
                    width: 179px;
                    height: 179px;
                    background: #FFFFFF;
                    border-radius: 2px;
                    border: 1px solid #D9D9D9;

                    .img_con {
                         width: 179px;
                         height: 179px;
                         background-repeat: no-repeat;
                         background-position: center center;
                         background-size: contain;
                    }

                    .del_con {
                         position: absolute;
                         top: 5px;
                         right: 5px;
                    }
               }
          }


     }
}

.upload_list {
     .upload_item {
          width: 90px;
          height: 90px;
          background: #FFFFFF;
          border: 1px solid #D9D9D9;
          margin-right: 15px;
          position: relative;

          .el-icon-error {
               color: #D9D9D9;
               font-size: 14px;
               position: absolute;
               right: -5px;
               top: -5px;

               &:hover {
                    color: #999;
               }
          }

          .excel_go {
               height: 90px;

               img {
                    width: 55px;
                    height: 55px;
               }

               p {
                    margin-top: 10px;
               }
          }

          .img_con {
               width: 100%;
               height: 100%;
               background-position: center center;
               background-repeat: no-repeat;
               background-size: contain;
          }
     }
}
</style>